module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jordan Newland 🍵","short_name":"Jordan Newland 🍵","description":"Creative UI/UX Designer and Web Developer based in the UK. Promoting a greener, more accessible web for all. Learn more.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#6a1b9a","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a54c41e606ba7b953e35102be71a25d2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
